.header {
    text-align: center;
    padding: 30px 0;
    margin-bottom: 15px;
}

.logo {
    max-width: 200px;
    display: inline-block;

    img {
        width: 100%;
    }
}

.btn-institutional {
    @include button-variant(#fff, $payment-main-color, darken($payment-main-color, 5%));

    &:hover,
    &:focus {
        background-color: darken($payment-main-color, 15%);
    }
}

.mb-2x {
    margin-bottom: 30px;
}

.box-inst {
    border-color: $payment-main-color;
    border-style: solid;
    border-width: 4px 0 4px 0;
    background: #fff;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .2);
    color: $payment-gray-color;
    padding: 20px 60px;
}

.institutional-form {
    margin: 0 auto 90px auto;
    width: 90%;
    text-align: center;
    padding: 20px 60px;

    h2 {
        margin-bottom: 40px;
    }

    .btn+.btn {
        margin-left: 20px;
    }
}

.input-group-addon {
    background-color: #fff;
}

.alert-plan {
    @include alert-variant(lighten($payment-main-color, 35%), $payment-main-color, $payment-darker-color);
}

.link {
    a {
        text-decoration: none;
        color: $payment-main-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.btns {
    a {
        color: #333;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}

.btn-none {
    border: none;
    background-color: transparent;
    color: $payment-main-color;
}

.footer {
    font-family: 'Raleway', sans-serif;
    background-color: $payment-main-color;
    padding: 40px 0;
    text-align: center;

    .logo {
        margin-bottom: 40px;
    }

    .social-icons {
        margin-top: 20px;
        text-align: center;

        a>i {
            padding-left: 18px;
            color: #fff;
        }
    }

    &__menu {
        margin: 50px 0;
        font-size: 11px;

        @media(max-width:1200px) {
            display: none;
        }

        li {
            display: inline-block;
            text-transform: uppercase;
            border-top: 1px solid #ffffff;
            border-bottom: 1px solid #ffffff;
            padding: 8px 0;
            margin: 0 15px;
            letter-spacing: 3px;
            font-weight: 400;

            &+li {
                margin-left: 15px;
            }

            a {
                text-decoration: none;
                color: #fff;
            }
        }
    }

    .privacy a {
        color: #fff;
    }
}

.copyright {
    text-align: center;
    color: #fff;
}

#register {
    .modal-body {
        text-align: left;
    }

    .modal-footer {
        text-align: center !important;
    }
}

.img-register {
    width: 100%
}