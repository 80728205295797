$fa-font-path: "/fonts" !default;

@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import 'node_modules/font-awesome/scss/font-awesome';
@import 'node_modules/card/dist/card.css';

@import 'vuejs/modal';

@import 'institutional/form';
@import 'institutional/payment';

.fw-bold {
    font-weight: 700 !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

small,
.small {
    font-size: 80% !important;
}

@media (min-width: 1200px) {
    .text-lg-left {
        text-align: left;
    }

    .pt-lg-5 {
        padding-top: 5rem;
    }

    .pl-lg-10 {
        padding-left: 10rem;
    }
}

@media (max-width: 1200px) {
    .text-sm-center {
        text-align: center;
    }

    .mt-sm-2 {
        margin-top: 2rem;
    }

    .mt-sm-5 {
        margin-top: 5rem;
    }

    .width-sm-100per {
        width: 100%;
    }
}