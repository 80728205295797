.credit-card-form {
    margin-bottom: 65px;
    padding: 25px;
}

.credit-flags {
    display: flex;
    margin-bottom: 15px;
}

.card-item {
    & + & {
        margin-left: 5px;
    }

    img {
        width: 70%;
    }
}

.card-wrapper {
    margin: 2rem 0 2rem 0;
}

.card-form {
    margin-top: 20px;
}

.payment-title {
    font-size: 30px;
    position: relative;

    span {
        margin-left: 10px;
    }

    &.collapsed {
        font-size: 28px;
        .fa {
            font-size: 36px;
        }
        .payment-chevron {
            font-size: 24px;
            transform: translateY(-50%);
        }
    }
}

.payment-chevron {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    transition: transform .3s;
}

.btn-institutional {
    @include button-variant(#fff, $payment-main-color, darken($payment-main-color, 5%));

    &:hover,
    &:focus {
        background-color: darken($payment-main-color, 15%);
    }
}
